<template>
  <div>
    <b-card>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col
              md="6"
            >
              <b-form-group>
                <label>Nombre o Razón social</label>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Nombre o Razón social"
                >
                  <b-form-input
                    v-model="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nombre o Razón social"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
            >
              <b-form-group>
                <label>Actividad económica</label>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Actividad económica"
                >
                  <b-form-input
                    v-model="activity"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Actividad económica"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="6"
            >
              <b-form-group>
                <label>Propietario</label>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Propietario"
                >
                  <b-form-input
                    v-model="ouner"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Propietario"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
            >
              <b-form-group>
                <label>NIT</label>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="NIT"
                >
                  <b-form-input
                    v-model="nit"
                    :state="errors.length > 0 ? false:null"
                    placeholder="NIT"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="match-height">
            <b-col lg="6">
              <b-row class="match-height">
                <!-- Bar Chart - Orders -->
                <b-col
                  lg="12"
                  md="6"
                >
                  <b-form-group>
                    <label>Teléfono</label>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="Teléfono"
                    >
                      <b-form-input
                        v-model="cel"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Teléfono"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="12"
                  md="6"
                >
                  <b-form-group>
                    <label>Correo electrónico</label>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="Correo electrónico"
                    >
                      <b-form-input
                        v-model="email"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Correo electrónico"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="6">
              <b-form-group>
                <label>Dirección</label>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Direccion"
                >
                  <b-form-textarea
                    v-model="address"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Dirección"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="6"
            >
              <section>
                <b-card
                  no-body
                >
                  <div class="item-img text-center">
                    <b-link>
                      <b-img
                        fluid
                        :src="require('@/assets/images/logo/logo.png')"
                      />
                    </b-link>
                  </div>
                </b-card>
                <div
                  class="d-flex justify-content-center"
                >
                  <b-button
                    variant="primary"
                  >
                    Cargar Imagen
                  </b-button>
                </div>
              </section>
            </b-col>
            <b-col
              md="6"
            >
              <div
                class="d-flex justify-content-start"
              >
                <b-button
                  variant="primary"
                  @click="updateData"
                >
                  Guardar
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/utils/validations/validations'
import companyData from './OrganizationData'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      name: '',
      activity: '',
      ouner: '',
      nit: '',
      cel: '',
      address: '',
      email: '',
      companyData: companyData.company,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.name = this.companyData[0].name
      this.activity = this.companyData[0].activity
      this.ouner = this.companyData[0].ouner
      this.nit = this.companyData[0].nit
      this.cel = this.companyData[0].cel
      this.address = this.companyData[0].address
      this.email = this.companyData[0].email
    },
    updateData() {
      this.$bvModal
        .msgBoxConfirm('¿Deseas modificar la información de la empresa?', {
          title: 'Informacion Empresarial',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          okTitleVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.companyData[0].name = this.name
            this.companyData[0].activity = this.activity
            this.companyData[0].ouner = this.ouner
            this.companyData[0].nit = this.nit
            this.companyData[0].cel = this.cel
            this.companyData[0].address = this.address
            this.companyData[0].email = this.email
            this.makeToast('success', 'Correcto', 'Información Actualizada')
          }
        })
    },
  },
}
</script>
