var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Nombre o Razón social")]),_c('validation-provider',{attrs:{"rules":"required","name":"Nombre o Razón social"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Nombre o Razón social"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Actividad económica")]),_c('validation-provider',{attrs:{"rules":"required","name":"Actividad económica"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Actividad económica"},model:{value:(_vm.activity),callback:function ($$v) {_vm.activity=$$v},expression:"activity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Propietario")]),_c('validation-provider',{attrs:{"rules":"required","name":"Propietario"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Propietario"},model:{value:(_vm.ouner),callback:function ($$v) {_vm.ouner=$$v},expression:"ouner"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("NIT")]),_c('validation-provider',{attrs:{"rules":"required","name":"NIT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"NIT"},model:{value:(_vm.nit),callback:function ($$v) {_vm.nit=$$v},expression:"nit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"lg":"6"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"lg":"12","md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Teléfono")]),_c('validation-provider',{attrs:{"rules":"required","name":"Teléfono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Teléfono"},model:{value:(_vm.cel),callback:function ($$v) {_vm.cel=$$v},expression:"cel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"12","md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Correo electrónico")]),_c('validation-provider',{attrs:{"rules":"required","name":"Correo electrónico"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Correo electrónico"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Dirección")]),_c('validation-provider',{attrs:{"rules":"required","name":"Direccion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Dirección","rows":"3"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('section',[_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"item-img text-center"},[_c('b-link',[_c('b-img',{attrs:{"fluid":"","src":require('@/assets/images/logo/logo.png')}})],1)],1)]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v(" Cargar Imagen ")])],1)],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.updateData}},[_vm._v(" Guardar ")])],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }