export default {
  company: [
    {
      name: 'SISTEMAS WEB',
      activity: 'Desarrollo de sistemas web a la medida',
      ouner: 'Marlon Navarro',
      nit: '7865321-5',
      cel: '55238946',
      address: 'SMP',
      email: 'NavarreteLoquero@gmail.com',
      picture: '',
    },
  ],
}
